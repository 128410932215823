<template>
  <div class="w1092" v-if="model">
    <!-- <h4 class="pageName">{{ $t('front.common.qna') }}</h4> -->
    <div class="board">
      <div class="boardrd first">
        <span class="ml10">{{model.title}}</span>
      </div>
      <div class="boardrd">
        <div>
          <span v-if="model.status === 'ANSWER'" class="">{{$t('front.qnaStatus.answer')}}</span>
          <span v-if="model.status === 'WAIT'" class="">{{$t('front.qnaStatus.wait')}}</span>
          <span class="gn">{{$t('front.qnaTypeCategory.' + model.faqType)}}</span>
        </div>
        <div>
          <!--span>{{model.memName}}</span-->
          <span>{{dateFormatForBoard(model.regDate)}}</span>
        </div>
      </div>
      <div class="boardbox">
        <p><em>Q</em>{{model.content}}</p>
        <p class="rdcon rdconbg mt20" v-if="replyContent">
          <em>A</em>{{replyContent}}
        </p>
      </div>
    </div>

    <div class="boardbtn">
      <ui-button :text="$t('front.board.prev')" :className="'grsbtn left'" v-if="prevId" @click="onClickPrevNext('prev')"/>
      <ui-button :text="$t('front.board.list')" :className="'orsbtn'" @click="onClickList"/>
      <ui-button :text="$t('front.board.next')" :className="'grsbtn right'" v-if="nextId" @click="onClickPrevNext('next')"/>
    </div>

  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'
import { getBoardCookie } from '@/libs/auth-helper'

export default {
  name: 'QnaRead',
  components: {
    UiButton
  },
  data () {
    return {
      boardType: 'faq',
      boardIdx: null,
      model: null,
      replyContent: '',
      prevId: null,
      nextId: null
    }
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    this.loadBoardDetail(this.boardIdx)

    this.idxList = getBoardCookie().split(',')
    console.log(this.idxList)

    if (this.idxList.length > 0) {
      for (let i = 0, iLen = this.idxList.length; i < iLen; i++) {
        if (this.idxList[i] === this.boardIdx.toString()) {
          if (this.idxList[i - 1]) {
            this.prevId = this.idxList[i - 1]
          }
          if (this.idxList[i + 1]) {
            this.nextId = this.idxList[i + 1]
          }
        }
      }
    }
  },
  methods: {
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      this.model = await this.getBoardDetail(params)
      this.replyContent = this.model.comment.length > 0 ? this.model.comment[0].content : ''
    },
    onClickPrevNext (type) {
      let id = this.prevId
      if (type === 'next') {
        id = this.nextId
      }
      this.$router.push({ name: 'qnaRead', params: { boardIdx: id } })
    },
    onClickList () {
      this.$router.push({ name: 'qna' })
    }
  }
}
</script>
<style scoped>
.boardrd {display: flex;align-items: center;justify-content: space-between;}
.boardrd pre {white-space: pre-wrap;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
